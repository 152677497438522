<template>
  <div class="row">
    <!-- Combo de seleção -->
    <div class="col-md-12 mb-3 px-0" v-if="enableFilter">
      <div class="py-1">
        <div class="md-form m-0">
          <v-select :disabled="disabled"
            :placeholder="placeholder"
            :label="labelField"
            ref='filterField'
            :searchable="searchable"
            :clearable="clearable"
            :options="options"
            @search="delayFetchOptions"
            @input="addSelectedOption"
            :filter-by="filterBy">
            <template slot="no-options">
              {{ noOptions }}
            </template>
            <template slot="option" slot-scope="option">
              <slot name="option-content">
                <div class="d-center" v-bind:class="{ 'option-disabled': isIncluded(option) }">
                  {{ getLabel(option) }}
                </div>
              </slot>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ getLabel(option) }}
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <!-- Tabela de selecionados -->
    <div class="col-12 col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th v-for="(column, index) in tableFields" :data-idx="index" :key="index" :width="column.width" >
              {{ column.title }}
            </th>
            <th v-if="enableStatus === null">
              <div v-if="enableFilter">
              {{ $t('receive')}}
              </div>
              <div v-else>
                <label class="ui-switch no-margin small switch-solid switch-warning">
                  <input tabindex="-1" :value="'all'" type="checkbox" ref="status" :checked="allSelected" v-on:change="toggleSelectAll"><span></span></label>
              </div>
            </th>
            <th>
            </th>
          </tr>
        </thead>

        <tbody class="content">
          <tr v-for="(item, line) in computedValue" :data-idx="line" :key="line" @click="onRowClicked(item, line, $event)">
            <td v-for="(column, index) in tableFields" :data-idx="index" :key="index">

              <!-- Combo -->
              <div v-if="isSelectable(column)" class="d-flex justify-content-center">
                <select v-model="item[column.name].selected" class="gray-border rounded">
                  <option v-if="semOpcoes(item[column.name])" disabled value="">Sem opções</option>
                  <option v-for="(option, index) in item[column.name].options" :value="option" :key="index">{{option.nome}}</option>
                </select>
              </div>

              <!-- Checkbox -->
              <div v-else-if="isToggleable(column)" class="d-flex justify-content-center">
                <label class="ui-switch no-margin small switch-solid switch-warning">
                <input tabindex="-1" :value="item[column.name]" type="checkbox" ref="status"
                  :checked="(item[column.name] == 1)" v-on:change="toggleColumn(line, column.name)"><span></span></label>
              </div>

              <!-- Dados inalteráveis -->
              <span v-else>{{ item[column.name] }}</span>
            </td>

            <!-- Habilita/desabilita sem remover -->
            <td v-if="enableStatus === null">
              <label class="ui-switch no-margin small switch-solid switch-warning">
              <input tabindex="-1" :value="index" type="checkbox" ref="status"
                :checked="(item.status == 1)" v-on:change="toggleStatus"><span></span></label>
            </td>

            <!-- Botão de remoção -->
            <td v-if="enableFilter">
              <div class="buttonClose d-inline-block" @click.stop="removeSelectedOption(item[idField])">
                <span ><i class="rc-Ativo-36 font-11"></i></span>
              </div>
            </td>
          </tr>

          <!-- Sem dados para exibir -->
          <tr v-if="computedValue.length <= 0">
            <td colspan="6">{{ emptyMessage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'CustomAdvancedMultipleSelect',
  props: {
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    enableFilter: {
      type: Boolean,
      default: true
    },
    enableStatus: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    apiUrl: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    emptyMessage: {
      type: String,
      default: global.instanceApp.$i18n.t('noting selected')
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    fetchOptions: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    searchField: {
      type: String,
      default: ''
    },
    labelField: {
      type: String,
      default: 'name'
    },
    idField: {
      type: String,
      default: 'id'
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    },
    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true
    },
    customLabel: {
      type: Function,
      default: null
    }
  },
  components: {
    vSelect
  },
  data () {
    return {
      searchTerm: null,
      selectedValue: null,
      timer: 0
    }
  },
  methods: {
    onRowClicked (dataItem, dataIndex, event) {
      this.$emit('rowClicked', { data: dataItem, index: dataIndex, event: event })
      return true
    },
    isIncluded: function (val) {
      let _me = this
      let selected = this.value.find((x) => {
        return x[_me.idField] === val[_me.idField]
      })

      if (selected !== undefined) {
        return true
      }
      return false
    },
    toggleSelectAll () {
      let activeStatus = this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      if (this.allSelected) {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = 2
          this.value.splice(i, 1, item)
        }
      } else {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = activeStatus
          this.value.splice(i, 1, item)
        }
      }
      this.$emit('toggleAll', this.value)
    },
    toggleStatus (e) {
      let index = e.target.value

      let item = this.value[index]

      item.status = ((e.target.checked) ? 1 : 2)
      this.$set(this.value, index, item)
      this.$emit('toggleStatus', item)
    },
    addSelectedOption (val) {
      let defaultStatus = 1
      if (this.enableStatus !== null) {
        defaultStatus = this.enableStatus
      }

      if (val != null) {
        if (!this.isIncluded(val)) {
          val.status = defaultStatus
          this.value.push(val)
          this.$emit('onInput', { data: val })
        }
        this.$refs.filterField.clearSelection()
      }
    },
    removeSelectedOption (val) {
      if (val != null) {
        let _this = this
        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element[_this.idField] === val
        })
        // remove element from selected options
        this.value.splice(index, 1)
        this.$emit('onRemoved', val)
      }
    },
    getLabel (option) {
      if (this.customLabel) {
        return this.customLabel(option)
      } else {
        return option[this.labelField]
      }
    },
    delayFetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(function () {
          _this.fetchOptions(search, loading)
        }, this.delay)
      }
    },
    isToggleable (column) {
      if (column.toggleable) {
        return true
      }
      return false
    },
    isSelectable (fields) {
      if (fields.selectable) {
        return true
      }
      return false
    },
    semOpcoes (values) {
      if (values.options.length > 0) {
        return false
      }

      return true
    },
    toggleColumn (line, property) {
      let index = line
      let item = this.value[index]
      item[property] = (item[property] ? 0 : 1)

      this.$set(this.value, index, item)
      this.$emit('toggleProperty', item, property)
    },
    isset (variable) {
      return variable !== 'undefined' && variable != null && variable !== ''
    },
    filterBy (option, label, search) {
      let splited = search.toLocaleLowerCase().split(' ')
      let lowerLabel = label.toLocaleLowerCase()

      return splited.reduce((founded, term) => {
        founded += lowerLabel.indexOf(term) > -1
        return founded
      })
    }
  },
  computed: {
    allSelected () {
      let _this = this
      if (_this.value.length <= 0) {
        return false
      }

      let activeStatus = _this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      let activeValues = _this.value.filter(function (x) {
        return x.status === activeStatus
      })

      if (activeValues.length === _this.value.length) {
        return true
      }

      return false
    },
    computedValue: function () {
      if (this.enableStatus === null) {
        return this.value
      }

      let _self = this

      return this.value.filter(function (status) {
        return status.status === _self.enableStatus
      })
    }
  }
}
</script>

<style scoped>
  .buttonClose:not(:disabled):not(.disabled) {
    cursor: pointer;
    font-size: 12px;
  }

  .option-disabled {
    cursor: not-allowed;
    color: #83dadb;
  }

  .gray-border {
    border-color: darkgrey;
    color: darkgrey;
    background-color: white;
  }

  .gray-border:after {
    background-color: darkgrey;
    color: darkgrey;
  }
</style>
